import { default as change_45passwordmy6pB7beAzMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account/change-password.vue?macro=true";
import { default as index4Fwnj9QEDQMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account/index.vue?macro=true";
import { default as account0jjMfFDMFhMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account.vue?macro=true";
import { default as indexEVUI3AnRmMMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id]/[category]/[[subCategory]]/index.vue?macro=true";
import { default as _91_91subCategory_93_938xfgaARjU3Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id]/[category]/[[subCategory]].vue?macro=true";
import { default as _91id_93Ecx3DhtKn7Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id].vue?macro=true";
import { default as indexowhEciTWc0Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/index.vue?macro=true";
import { default as appsjlXIyu4l32Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps.vue?macro=true";
import { default as complete_45signup14hEBcmmEXMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/complete-signup.vue?macro=true";
import { default as confirmu10W2eBFLtMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/confirm.vue?macro=true";
import { default as first_45workspacetTaouQZl09Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/first-workspace.vue?macro=true";
import { default as forget_45passwordr2BwpLNKJBMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/forget-password.vue?macro=true";
import { default as invite_45set_45passwordKZKzEE7vgqMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/invite-set-password.vue?macro=true";
import { default as loginFOfc1o8iUSMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/login.vue?macro=true";
import { default as reset_45password5spVlPsZp0Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/reset-password.vue?macro=true";
import { default as signupZIA0d6KvWFMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/signup.vue?macro=true";
import { default as verify_45emailjgMFpTEd20Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/verify-email.vue?macro=true";
import { default as indexoXEay3cdfFMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/index.vue?macro=true";
import { default as publisher_45appsvMb91LhCxVMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/publisher-apps.vue?macro=true";
import { default as publishershpmkcWxvjFMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/publishers.vue?macro=true";
import { default as abilitiesoHkxU4PgqNMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id]/abilities.vue?macro=true";
import { default as settingsGxPr6fziF8Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id]/settings.vue?macro=true";
import { default as _91id_93ap0W9N67ZIMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id].vue?macro=true";
import { default as indexaehJa3meZgMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/index.vue?macro=true";
import { default as rolesrKbMdeWis4Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles.vue?macro=true";
import { default as users3z3TVkAERLMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/users.vue?macro=true";
import { default as accessksjd2sEsM6Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/access.vue?macro=true";
import { default as analyticszWmYcGOPE4Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/analytics.vue?macro=true";
import { default as apps0TzHb9yTIRMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/apps.vue?macro=true";
import { default as detailss8c3mYdIzBMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/details.vue?macro=true";
import { default as indexRXsXggR4PJMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/index.vue?macro=true";
import { default as membersaQeTvWO6KbMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/members.vue?macro=true";
import { default as paymentowCw6brZ7JMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/payment.vue?macro=true";
import { default as _91id_937l5kfqbEmBMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id].vue?macro=true";
import { default as indexiDpNWYWhjOMeta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/index.vue?macro=true";
import { default as workspaces35BaFkQE71Meta } from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces.vue?macro=true";
export default [
  {
    name: account0jjMfFDMFhMeta?.name,
    path: "/account",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account.changePassword",
    path: "change-password",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsjlXIyu4l32Meta?.name,
    path: "/apps",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps.vue").then(m => m.default || m),
    children: [
  {
    name: "apps-id",
    path: ":id()",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91subCategory_93_938xfgaARjU3Meta?.name,
    path: ":category()/:subCategory?",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id]/[category]/[[subCategory]].vue").then(m => m.default || m),
    children: [
  {
    name: "apps-id-category-subCategory",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/[id]/[category]/[[subCategory]]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "apps",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/apps/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "complete-signup",
    path: "/auth/complete-signup",
    meta: complete_45signup14hEBcmmEXMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/complete-signup.vue").then(m => m.default || m)
  },
  {
    name: "auth.confirm",
    path: "/auth/confirm",
    meta: confirmu10W2eBFLtMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: "first-workspace",
    path: "/auth/first-workspace",
    meta: first_45workspacetTaouQZl09Meta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/first-workspace.vue").then(m => m.default || m)
  },
  {
    name: "forget-password",
    path: "/auth/forget-password",
    meta: forget_45passwordr2BwpLNKJBMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "invite-set-password",
    path: "/auth/invite-set-password",
    meta: invite_45set_45passwordKZKzEE7vgqMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/invite-set-password.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/auth/login",
    meta: loginFOfc1o8iUSMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/auth/reset-password",
    meta: reset_45password5spVlPsZp0Meta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/auth/signup",
    meta: signupZIA0d6KvWFMeta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "verify-email",
    path: "/auth/verify-email",
    meta: verify_45emailjgMFpTEd20Meta || {},
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management.apps",
    path: "/management/publisher-apps",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/publisher-apps.vue").then(m => m.default || m)
  },
  {
    name: "management.publisher",
    path: "/management/publishers",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/publishers.vue").then(m => m.default || m)
  },
  {
    name: rolesrKbMdeWis4Meta?.name,
    path: "/management/roles",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles.vue").then(m => m.default || m),
    children: [
  {
    name: "management-roles-id",
    path: ":id()",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "management-roles-id-abilities",
    path: "abilities",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id]/abilities.vue").then(m => m.default || m)
  },
  {
    name: "management-roles-id-settings",
    path: "settings",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/[id]/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "management.roles",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/roles/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "management.users",
    path: "/management/users",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/management/users.vue").then(m => m.default || m)
  },
  {
    name: workspaces35BaFkQE71Meta?.name,
    path: "/workspaces",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_937l5kfqbEmBMeta?.name,
    path: ":id()",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "workspaces-id-access",
    path: "access",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/access.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id-analytics",
    path: "analytics",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/analytics.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id-apps",
    path: "apps",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/apps.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id-details",
    path: "details",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id-members",
    path: "members",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/members.vue").then(m => m.default || m)
  },
  {
    name: "workspaces-id-payment",
    path: "payment",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/[id]/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "workspaces",
    path: "",
    component: () => import("/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/pages/workspaces/index.vue").then(m => m.default || m)
  }
]
  }
]