
        import { defineNuxtPlugin } from '#imports'
        import FloatingVue from 'floating-vue'
        
        export default defineNuxtPlugin((nuxtApp) => {
          nuxtApp.vueApp.use(FloatingVue, {
            themes: {
              'navigation-dropdown': {
                $extend: 'dropdown',
                placement: 'right',
                strategy: 'fixed',
              },
              'app-config-tooltip': {
                $extend: 'tooltip',
              },
            },
          })
        })
      