import revive_payload_client_M3zaW1laO9 from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kfhseaY21U from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Zye0R4OCrn from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_Cy7uajIl92 from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_mMZU39zleg from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_w3EJvArhty from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_51kv24itdE from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zirLocvSLb from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yjfreYoPJr from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.18.1_typescript@5.6.3_vue@3.4.31_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_qhBBIAiI24 from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@planetscale+database@1.19.0_@types+node@18.19.39_drizzle-o_v7fivfbd6pbpjrnk5g35a76dde/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_RBgcxwofRp from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_b0UF7kIDhu from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/.nuxt/floating-vue.mjs";
import casl_QTBkbB6FCs from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/casl.ts";
import font_awesome_4Kz3WSwqBj from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/font-awesome.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/sentry.client.ts";
export default [
  revive_payload_client_M3zaW1laO9,
  unhead_kfhseaY21U,
  router_Zye0R4OCrn,
  supabase_client_Cy7uajIl92,
  payload_client_mMZU39zleg,
  navigation_repaint_client_w3EJvArhty,
  check_outdated_build_client_51kv24itdE,
  chunk_reload_client_zirLocvSLb,
  plugin_vue3_yjfreYoPJr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qhBBIAiI24,
  i18n_RBgcxwofRp,
  plugin_client_b0UF7kIDhu,
  floating_vue_EIcJ7xiw0h,
  casl_QTBkbB6FCs,
  font_awesome_4Kz3WSwqBj,
  sentry_client_shVUlIjFLk
]